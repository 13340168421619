import React, { Component } from "react";
import Popup from "reactjs-popup";
import { UserService } from "../service/user.service";

export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",
  reg = "reg",
  login = "login",
}

interface Props {
  handleClose: () => any;
  tryLogin: (id: any, pw: any) => any;
  changePopup: (value: any) => any;
  handleChange: (viewName: string) => any;
}

interface State {
  ID: string;
  PW: string;
}

export class Login extends Component<Props, State> {
  userService = new UserService();
  constructor(props: Props) {
    super(props);

    this.state = {
      ID: "",
      PW: "",
    };
  }

  render() {
    return (
      <Popup
        // key={`main_popup_note_${pop.idx}`}
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "none",
          border: "none",
          width: "none",
        }}
        overlayStyle={{
          overflow: "scroll",
        }}
      >
        {(close) => (
          <>
            <div
              className="modal loginModal subpage-modal fade show"
              role="dialog"
              data-backdrop="static"
              style={{ display: "block" }}
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <button
                    className="modal-close-btn modal-close"
                    data-dismiss="modal"
                    onClick={() => {
                      this.props.handleClose();
                    }}
                  ></button>
                  <div className="modal-menu">
                    <button className="active">
                      <div className="icon">
                        <i className="fas fa-key"></i>
                      </div>
                      <p className="kr-text">로그인</p>
                      <p className="en-text">Login</p>
                    </button>
                    <button
                      className="join-link"
                      onClick={() => {
                        this.props.handleChange(popupView.reg); //회원가입
                      }}
                    >
                      <div className="icon">
                        <i className="fas fa-user-plus"></i>
                      </div>
                      <p className="kr-text">회원가입</p>
                      <p className="en-text">Sign Up</p>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="modal-title">
                      <h5 className="sub">LOGIN</h5>
                      <h2 className="title">로그인</h2>
                    </div>
                    <div className="modal-information mb-5">
                      <div className="text-container text-center">
                        <p>
                          <i className="fas fa-star highlight mr-1"></i> PD
                          카지노에 오신것을 환영합니다.
                        </p>
                      </div>
                    </div>
                    <div className="form-container">
                      <div className="form-group">
                        <div className="labels">
                          <span>아이디</span>
                          <span className="asterisk">*</span>
                        </div>
                        <div className="infos">
                          <div className="input-container">
                            <input
                              type="text"
                              placeholder="아이디"
                              onChange={({ target: { value } }) =>
                                this.setState({ ID: value })
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-container">
                      <div className="form-group">
                        <div className="labels">
                          <span>비밀번호</span>
                          <span className="asterisk">*</span>
                        </div>
                        <div className="infos">
                          <div className="input-container">
                            <input
                              type="password"
                              placeholder="비밀번호"
                              onChange={({ target: { value } }) =>
                                this.setState({ PW: value })
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        className="btn-style login-btn modal-close"
                        data-dismiss="modal"
                        onClick={() => {
                          if (
                            this.state.ID == null ||
                            this.state.ID == "" ||
                            this.state.PW == "" ||
                            this.state.PW == null
                          ) {
                            return;
                          }
                          this.props.tryLogin(this.state.ID, this.state.PW);
                        }}
                      >
                        로그인
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </>
        )}
      </Popup>
    );
  }
}
