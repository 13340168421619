import React, { Component } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import Popup from "reactjs-popup";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import { UserService } from "../service/user.service";
import { ConvertDate } from "../utility/help";

export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",
  reg = "reg",
  login = "login",
  comp = "comp",
}

export enum noticeView {
  none = "none",
  list = "list",
  view = "view",
}

const CustomTableCell = styled(TableCell)`
  background: linear-gradient(#2c2c2c 0%, #1c1c1c 100%);
  color: #dddddd;
  line-height: 44px;
  text-align: center;
  font-weight: bold;
  font-family: "nanumgothic", sans-serif;
`;

const CustomTableCell2 = styled(TableCell)`
  background: rgba(0, 0, 0, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  color: #ffffff;
  line-height: 40px;
  padding: 0 0 0 15px;
  font-weight: bold;
`;

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

function Row(props: { row: any }) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  let ref = "";
  if (row.ref != null) {
    ref = row.ref.contents;
  }

  return (
    <React.Fragment>
      <TableRow className={classes.root} key={row._id}>
        <CustomTableCell2 className="list_notice1">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </CustomTableCell2>
        <CustomTableCell2
          align="center"
          onClick={() => setOpen(!open)}
          style={{ color: "#ffffff" }}
          className="list_notice2"
        >
          {row.title}
        </CustomTableCell2>
        <CustomTableCell2
          align="center"
          style={{ color: "#ffffff" }}
          className="list_notice2"
        >
          {ConvertDate(row.row)}
        </CustomTableCell2>
        <CustomTableCell2
          align="center"
          className="list_notice2"
        ></CustomTableCell2>
      </TableRow>
      <TableRow>
        <CustomTableCell2
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <div
                style={{
                  height: "400px",
                  overflowY: "scroll",
                }}
              >
                <div dangerouslySetInnerHTML={{ __html: row.contents }}></div>
              </div>
            </Box>
          </Collapse>
        </CustomTableCell2>
      </TableRow>
    </React.Fragment>
  );
}

interface Props {
  handleClose: () => any;
  handleChange: (viewName: string) => any;
}

interface State {
  notices: any;
  mode: string;
  title: string;
  contents: string;
  date: string;
  index: number;
  no: string;
  page: number;
  maxCount: number;
}

export class Notice extends Component<Props, State> {
  userService = new UserService();

  constructor(props: Props) {
    super(props);
    this.state = {
      notices: [],
      mode: noticeView.list,
      title: "",
      contents: "",
      date: "",
      index: 0,
      no: "",
      page: 1,
      maxCount: 0,
    };
  }

  componentDidMount() {
    this.handleGetNotices();
  }

  handleGetNotices = () => {
    this.userService.get_user_notices().then((data: any) => {
      console.log(data);
      if (data.status === "success") {
        this.setState({
          notices: data.notices,
          maxCount: data.maxCount,
        });
      }
    });
  };

  prevNotice = (index: number) => {
    console.log("index : " + index);
    if (index !== 0) {
      this.state.notices.map((info: any, idx: any) => {
        if (idx === index - 1) {
          console.log("index : " + index);
          this.setState({
            title: info.title,
            contents: info.contents,
            date: info.lastDate,
            index: idx,
            no: info.no,
          });
        }
      });
    }
  };

  nextNotice = (index: number) => {
    console.log("index : " + index);
    if (index !== this.state.maxCount - 1) {
      this.state.notices.map((info: any, idx: any) => {
        if (idx === index + 1) {
          console.log("index : " + index);
          this.setState({
            title: info.title,
            contents: info.contents,
            date: info.lastDate,
            index: idx,
            no: info.no,
          });
        }
      });
    }
  };

  render() {
    let notices: any = [];
    let pages: any = [];
    let pageSize = 5;
    let maxCount = this.state.maxCount;
    let totPage = Math.floor(maxCount / pageSize) + 1;
    let start = (this.state.page - 1) * pageSize;
    let end = this.state.page * pageSize - 1;

    this.state.notices.map((info: any, index: any) => {
      if (index >= start && index <= end) {
        info.no =
          maxCount - (start + (index - pageSize * (this.state.page - 1)));
        notices.push(info);
      }
    });

    for (let i = 1; i <= totPage; i++) {
      pages.push({ no: i });
    }

    console.log(this.state.mode)
    const RenderList = () => {
      if (this.state.mode !== noticeView.list) {
        return <></>;
      }
      return (
        <>
          <table className="bs-table">
            <thead>
              <tr>
                <th>번호</th>
                <th>내용</th>
                <th>날짜</th>
              </tr>
            </thead>
            <tbody>
              {notices.map((info: any, index: any) => (
                <>
                  <tr
                    data-toggle="modal"
                    data-dismiss="modal"
                  >
                    <td className="count">{info.no}</td>
                    <td className="title">
                      <a
                        onClick={() => {
                          this.setState({
                            title: info.title,
                            contents: info.contents,
                            date: info.lastDate,
                            index: index,
                            no: info.no,
                            mode: noticeView.view,
                          });
                        }}
                      >
                        {info.title}
                      </a>
                    </td>
                    <td className="date">{ConvertDate(info.lastDate)}</td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
          <div className="modal-footer">
            <ul className="pagination">
              <li className="turn-pg pr-2">
                <a

                  onClick={() => {
                    this.setState({
                      page: 1,
                    });
                  }}
                >
                  <i className="fas fa-angle-double-left"></i>{" "}
                  <span className="text ml-1">Prev</span>
                </a>
              </li>
              {pages.map((page: any) => {
                if (page.no === this.state.page) {
                  return (
                    <>
                      <li>
                        <a

                          className="active"
                          onClick={() => {
                            this.setState({
                              page: page.no,
                            });
                          }}
                        >
                          {page.no}
                        </a>
                      </li>
                    </>
                  );
                } else {
                  return (
                    <>
                      <li>
                        <a
                          onClick={() => {
                            this.setState({
                              page: page.no,
                            });
                          }}
                        >
                          {page.no}
                        </a>
                      </li>
                    </>
                  );
                }
              })}

              <li className="turn-pg pl-2">
                <a
                  onClick={() => {
                    this.setState({
                      page: totPage,
                    });
                  }}
                >
                  <span className="text mr-1">Next</span>{" "}
                  <i className="fas fa-angle-double-right"></i>
                </a>
              </li>
            </ul>
          </div>
        </>
      );
    };

    const RenderView = () => {
      if (this.state.mode !== noticeView.view) {
        return <></>;
      }
      return (
        <>
          <table className="bs-table">
            <thead>
              <tr>
                <th>번호</th>
                <th>내용</th>
                <th>날짜</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="count">{this.state.no}</td>
                <td className="title">
                  <a >{this.state.title}</a>{" "}
                </td>
                <td className="date">{ConvertDate(this.state.date)}</td>
              </tr>
            </tbody>
          </table>
          <div className="message-content">
            <div className="inner-container">
              <div
                dangerouslySetInnerHTML={{ __html: this.state.contents }}
              ></div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="btn-style"
              data-toggle="modal"
              data-dismiss="modal"
              onClick={() => {
                this.setState({
                  mode: noticeView.list,
                });
              }}
            >
              목록가기
            </button>
            <button
              className="btn-style dark-bg"
              onClick={() => {
                this.prevNotice(this.state.index);
              }}
            >
              이전글
            </button>
            <button
              className="btn-style dark-bg"
              onClick={() => {
                this.nextNotice(this.state.index);
              }}
            >
              다음글
            </button>
          </div>
        </>
      );
    };

    return (
      <Popup
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "none",
          border: "none",
          width: "none",
        }}
        overlayStyle={{
          overflow: "scroll",
        }}
      >
        {(close) => (
          <>
            <div
              className="modal noticeModal subpage-modal "
              role="dialog"
              data-backdrop="static"
              style={{ display: "block" }}
            >
              <div className="modal-dialog size-md">
                <div className="modal-content">
                  <button
                    className="modal-close-btn modal-close"
                    data-dismiss="modal"
                    onClick={() => {
                      this.props.handleClose();
                    }}
                  ></button>
                  <div className="modal-menu">
                    <button
                      className="event-link"
                      onClick={() => {
                        this.props.handleChange(popupView.help);
                      }}
                    >
                      <div className="icon">
                        <i className="fas fa-calendar-alt"></i>
                      </div>
                      <p className="kr-text">고객센터</p>
                      <p className="en-text">HELP</p>
                    </button>
                    <button className="active">
                      <div className="icon">
                        <i className="fas fa-bullhorn"></i>
                      </div>
                      <p className="kr-text">공지사항</p>
                      <p className="en-text">Notice</p>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="modal-title">
                      <h5 className="sub">Notice</h5>
                      <h2 className="title">공지사항</h2>
                    </div>
                    {RenderList()}
                    {RenderView()}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Popup>
    );
  }
}
