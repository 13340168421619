import React, { Component } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import Popup from "reactjs-popup";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import { confirmAlert } from "react-confirm-alert"; // Import
import Typography from "@material-ui/core/Typography";
import { UserService } from "../service/user.service";
import { ConvertDate, HelpStatus } from "../utility/help";

export enum helpView {
  none = "none",
  write = "write",
  view = "view",
  list = "list",
}

export enum popupView {
  none = "none",
  comp = "comp",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",

  reg = "reg",
  login = "login",
}

const CustomTableCell = styled(TableCell)`
  background: linear-gradient(#2c2c2c 0%, #1c1c1c 100%);
  color: #dddddd;
  line-height: 44px;
  text-align: center;
  font-weight: bold;
  font-family: "nanumgothic", sans-serif;
`;

const CustomTableCell2 = styled(TableCell)`
  background: rgba(0, 0, 0, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  color: #ffffff;
  line-height: 40px;
  padding: 0 0 0 15px;
  font-weight: bold;
`;

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

const service = new UserService();

function Row(props: { row: any }) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  let ref = "";
  if (row.ref != null) {
    ref = row.ref.contents;
  }

  const RenderRef = () => {
    if (ref != null && ref !== "") {
      return (
        <Box margin={1}>
          <Typography
            variant="h6"
            gutterBottom
            component="div"
            color={"secondary"}
          >
            답변
          </Typography>
          <div dangerouslySetInnerHTML={{ __html: ref }}></div>
        </Box>
      );
    }
  };

  return (
    <React.Fragment>
      <TableRow className={classes.root} key={row._id}>
        <CustomTableCell2>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              service.do_help_message_read(row._id);
              setOpen(!open);
            }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </CustomTableCell2>
        <CustomTableCell2
          align="center"
          onClick={() => {
            service.do_help_message_read(row._id);
            setOpen(!open);
          }}
          style={{ color: "#ffffff" }}
        >
          {row.title}
        </CustomTableCell2>
        <CustomTableCell2 align="center" style={{ color: "#ffffff" }}>
          {ConvertDate(row.regDate)}
        </CustomTableCell2>
        <CustomTableCell2 align="center" style={{ color: "#ffffff" }}>
          {HelpStatus(row.status)}
        </CustomTableCell2>
      </TableRow>
      <TableRow>
        <CustomTableCell2
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <div dangerouslySetInnerHTML={{ __html: row.contents }}></div>
            </Box>

            {RenderRef()}
          </Collapse>
        </CustomTableCell2>
      </TableRow>
    </React.Fragment>
  );
}

interface Props {
  handleClose: () => any;
  handleChange: (viewName: string) => any;
}

interface State {
  helps: any;
  mode: string;

  title: string;
  contents: string;
  status: string;
  lastDate: string;
  ref: string;
  maxCount: number;
  index: number;
  no: string;
  page: number;
}

export class Help extends Component<Props, State> {
  userService = new UserService();

  constructor(props: Props) {
    super(props);
    this.state = {
      helps: [],
      mode: helpView.list,
      title: "",
      contents: "",
      status: "",
      lastDate: "",
      ref: "",
      maxCount: 0,
      index: 0,
      no: "",
      page: 1,
    };
  }

  componentDidMount() {
    this.handleGetNotices();
  }

  handleGetNotices = () => {
    this.userService.get_help_list().then((data: any) => {
      console.log(data);
      if (data.status === "success") {
        this.setState({ helps: data.helps, maxCount: data.maxCount });
      }
    });
  };

  prevHelp = (index: number) => {
    console.log("index : " + index);
    if (index !== 0) {
      this.state.helps.map((info: any, idx: any) => {
        if (idx === index - 1) {
          console.log("index : " + index);
          this.setState({
            title: info.title,
            contents: info.contents,
            lastDate: info.lastDate,
            status: info.status,
            ref: info.ref != null ? info.ref.contents : "",
            index: idx,
            no: info.no,
          });
        }
      });
    }
  };

  nextHelp = (index: number) => {
    console.log("index : " + index);
    if (index !== this.state.maxCount - 1) {
      this.state.helps.map((info: any, idx: any) => {
        if (idx === index + 1) {
          console.log("index : " + index);
          this.setState({
            title: info.title,
            contents: info.contents,
            lastDate: info.lastDate,
            status: info.status,
            ref: info.ref != null ? info.ref.contents : "",
            index: idx,
            no: info.no,
          });
        }
      });
    }
  };

  handleSaveHelp = (title: string, contents: string) => {
    console.log(title)
    console.log(contents)
    if (title === "") {
      confirmAlert({
        title: "고객센터",
        message: "타이틀을 입력해주세요.",
        buttons: [
          {
            label: "확인",
            onClick: () => {
              this.handleGetNotices();
            },
          },
        ],
      });
      return;
    }
    if (contents === "") {
      confirmAlert({
        title: "고객센터",
        message: "내용을 입력해주세요.",
        buttons: [
          {
            label: "확인",
            onClick: () => {
              this.handleGetNotices();
            },
          },
        ],
      });
      return;
    }

    this.userService.user_wirte_help(title, contents).then((date: any) => {
      if (date.status === "success") {
        confirmAlert({
          title: "고객센터",
          message: "게시물이 등록되었습니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                this.handleGetNotices();
              },
            },
          ],
        });
      } else {
        confirmAlert({
          title: "고객센터",
          message: "게시물이 등록되었습니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
      }
    });

    this.props.handleClose();
  };

  render() {
    let helps: any = [];
    let pages: any = [];
    let pageSize = 5;
    let maxCount = this.state.maxCount;
    let totPage = Math.floor(maxCount / pageSize) + 1;
    let start = (this.state.page - 1) * pageSize;
    let end = this.state.page * pageSize - 1;

    console.log("maxCount : " + this.state.maxCount);
    console.log(
      "totPage : " + totPage + ", start : " + start + ", end : " + end
    );

    this.state.helps.map((info: any, index: any) => {
      if (index >= start && index <= end) {
        info.no =
          maxCount - (start + (index - pageSize * (this.state.page - 1)));
        helps.push(info);
      }
    });

    for (let i = 1; i <= totPage; i++) {
      pages.push({ no: i });
    }

    const RenderRef = () => {
      if (this.state.ref != null && this.state.ref !== "") {
        return (
          <Box margin={1}>
            <Typography
              variant="h6"
              gutterBottom
              component="div"
              color={"secondary"}
            >
              답변
            </Typography>
            <div dangerouslySetInnerHTML={{ __html: this.state.ref }}></div>
          </Box>
        );
      }
    };

    const RenderList = () => {
      if (this.state.mode !== helpView.list) {
        return <></>;
      }
      return (
        <>
          <table className="bs-table">
            <thead>
              <tr>
                {/* <th>상태</th> */}
                <th>내용</th>
                <th>날짜</th>
                <th>상태</th>
              </tr>
            </thead>
          </table>
          <table className="bs-table with-depth">
            <tbody>
              {helps.map((info: any, index: any) => (
                <>
                  <tr
                    className="depth-click"
                    onClick={() => {
                      service.do_help_message_read(info._id);
                      this.setState({
                        title: info.title,
                        contents: info.contents,
                        lastDate: info.lastDate,
                        status: info.status,
                        ref: info.ref != null ? info.ref.contents : "",
                        mode: helpView.view,
                        index: index,
                        no: info.no,
                      });
                    }}
                  >
                    {/* <td className="title">{info.no}</td> */}
                    <td style={{width: '33.333%'}}>{info.title}</td>
                    <td style={{width: '33.333%'}} >{ConvertDate(info.lastDate)}</td>
                    <td style={{width: '33.333%'}} >{HelpStatus(info.status)}</td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
          <div className="modal-footer">
            <ul className="pagination m-0">
              <li className="turn-pg pr-2">
                <a
                  href="javascript:;"
                  onClick={() => {
                    this.setState({
                      page: 1,
                    });
                  }}
                >
                  <i className="fas fa-angle-double-left"></i>{" "}
                  <span className="text ml-1">Prev</span>
                </a>
              </li>

              {pages.map((page: any) => {
                if (page.no === this.state.page) {
                  return (
                    <>
                      <li>
                        <a
                          href="javascript:;"
                          className="active"
                          onClick={() => {
                            this.setState({
                              page: page.no,
                            });
                          }}
                        >
                          {page.no}
                        </a>
                      </li>
                    </>
                  );
                } else {
                  return (
                    <>
                      <li>
                        <a
                          href="javascript:;"
                          onClick={() => {
                            this.setState({
                              page: page.no,
                            });
                          }}
                        >
                          {page.no}
                        </a>
                      </li>
                    </>
                  );
                }
              })}

              <li className="turn-pg pl-2">
                <a
                  href="javascript:;"
                  onClick={() => {
                    this.setState({
                      page: totPage,
                    });
                  }}
                >
                  <span className="text mr-1">Next</span>{" "}
                  <i className="fas fa-angle-double-right"></i>
                </a>
              </li>
            </ul>
       
          </div>
          
        </>
      );
    };

    const RenderView = () => {
      if (this.state.mode !== helpView.view) {
        return <></>;
      }
      return (
        <>
          <table className="bs-table">
            <thead>
              <tr>
                <th>번호</th>
                <th>내용</th>
                <th>날짜</th>
                <th>상태</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="count">{this.state.no}</td>
                <td className="title">
                  <a href="javascript:;">{this.state.title}</a>{" "}
                </td>
                <td className="date">{ConvertDate(this.state.lastDate)}</td>
                <td>{HelpStatus(this.state.status)}</td>
              </tr>
            </tbody>
          </table>
          <div className="message-content">
            <div className="inner-container">
              <div
                dangerouslySetInnerHTML={{ __html: this.state.contents }}
              ></div>

              {
                this.state.ref != null && (
                <div>
                  <br/>
                  <br/>
                  <i className="fa fa-reply" aria-hidden="true"></i>답변확인
                  <br/>
                <div
                  dangerouslySetInnerHTML={{ __html: this.state.ref }}
                ></div>

                </div>
                )
              }
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="btn-style"
              data-toggle="modal"
              data-dismiss="modal"
              onClick={() => {
                this.setState({
                  mode: helpView.list,
                });
              }}
            >
              목록가기
            </button>
            <button
              className="btn-style dark-bg"
              onClick={() => {
                this.prevHelp(this.state.index);
              }}
            >
              이전글
            </button>
            <button
              className="btn-style dark-bg"
              onClick={() => {
                this.nextHelp(this.state.index);
              }}
            >
              다음글
            </button>
          </div>
        </>
      );
    };

    const RenderWrite = () => {
      if (this.state.mode !== helpView.write) {
        return <></>;
      }

      return (
        <>
          <div className="form-container">
            <div className="form-group">
              <div className="labels">
                <span>제목</span>
                <span className="asterisk">*</span>
              </div>
              <div className="infos">
                <div className="input-container">
                  <input
                    type="text"
                    placeholder="제목을 입력 하세요."
                    onChange={(e) =>
                      this.setState({
                        title: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="form-group">
              <div className="labels">
                <span>내용</span>
                <span className="asterisk">*</span>
              </div>
              <div className="infos">
                <div className="input-container">
                  <textarea
                    rows={4}
                    cols={20}
                    maxLength={300}
                    placeholder="내용을 입력하세요"
                    onChange={(e) =>
                      this.setState({
                        contents: e.target.value,
                      })
                    }
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="btn-style"
              onClick={() => this.handleSaveHelp(this.state.title, this.state.contents)}
            >
              저장 하기
            </button>
          </div>
        </>
      );
    };
    return (
      <Popup
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "none",
          border: "none",
          width: "none",
        }}
        overlayStyle={{
          overflow: "scroll",
        }}
      >
        {(close) => (
          <>
            <div
              className="modal noticeModal subpage-modal fade show"
              role="dialog"
              data-backdrop="static"
              style={{ display: "block" }}
            >
              <div className="modal-dialog size-md">
                <div className="modal-content">
                  <button
                    className="modal-close-btn modal-close"
                    data-dismiss="modal"
                    onClick={() => {
                      this.props.handleClose();
                    }}
                  ></button>
                  <div className="modal-menu">
                    <button
                      className={
                        this.state.mode === helpView.write
                          ? "event-link"
                          : "active"
                      }
                      onClick={() => {
                        this.setState({
                          mode: helpView.list,
                        });
                      }}
                    >
                      <div className="icon">
                        <i className="fas fa-calendar-alt"></i>
                      </div>
                      <p className="kr-text">고객센터</p>
                      <p className="en-text">HELP</p>
                    </button>
                    <button
                      className={
                        this.state.mode === helpView.write
                          ? "active"
                          : "event-link"
                      }
                      onClick={() => {
                        this.setState({
                          mode: helpView.write,
                        });
                      }}
                    >
                      <div className="icon">
                        <i className="fas fa-bullhorn"></i>
                      </div>
                      <p className="kr-text">새글쓰기</p>
                      <p className="en-text">Write</p>
                    </button>
                    <button
                      className="event-link"
                      onClick={() => {
                        this.props.handleChange(popupView.notice);
                      }}
                    >
                      <div className="icon">
                        <i className="fas fa-bullhorn"></i>
                      </div>
                      <p className="kr-text">공지사항</p>
                      <p className="en-text">Notice</p>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="modal-title">
                      <h5 className="sub">HELP</h5>
                      <h2 className="title">고객센터</h2>
                    </div>
                    <div className="modal-information mb-3">
                      <div className="icon-container">
                        <img src="img/icon/diamond-purple.png" />
                      </div>
                      <div className="text-container text-left">
                        <p>
                          1. 포인트 및 보유머니는 게임머니로 전환해야 게임이
                          가능합니다.
                        </p>
                        <p>
                          <span className="highlight">
                            2. 충전 신청은 꼭 계좌문의를 먼저 한 후, 입금 후
                            충전신청을 해주세요.
                          </span>
                        </p>
                        <p>
                          3. 기타 문의사항은 고객센터에 글을 남겨주시면
                          실시간으로 답변드리겠습니다.
                        </p>
                      </div>
                    </div>
                    <div className="modal-title" 
                        style={{width : '100px'}}
                        >
                      {/* <button
                        className="mypage-btn mypage-link"
                        onClick={async() => {
                          const date = await service.admin_del_all_help()
                          if (date.status === "success") {
                            confirmAlert({
                              title: "고객센터",
                              message: "모두 삭제되었습니다.",
                              buttons: [
                                {
                                  label: "확인",
                                  onClick: () => window.location.reload()
                                }
                              ],
                            });
                          } else {
                            confirmAlert({
                              title: "고객센터",
                              message: "새로고침이후에 다시 이용해주세요.",
                              buttons: [
                                {
                                  label: "확인",
                                  onClick: () => window.location.reload()
                                },
                              ],
                            });
                          }
                        }}
                      >
                      <i className="fa fa-cog"></i> 전체 삭제
                    </button> */}
                    </div>

                    {RenderList()}
                    {RenderView()}
                    {RenderWrite()}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Popup>
    );
  }
}
